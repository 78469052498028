.ant-menu-inline-collapsed {
    width: 78px !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

#patientPersonalForm .ant-form-item {
    display: block !important;
}

/* chat */
.cwaChat .ant-popover-inner-content {
    padding: 0;
    border-radius: 8px;
}

.cwaChat .ant-popover-arrow {
    border-top-color: #1890ff !important;
    border-left-color: #1890ff !important;
}

/* .cwaChat .ant-drawer-body {
    padding: 0;
} */

.cwaSketchBackgroundContentList .ant-popover-inner-content {
    padding: 5px 0 5px 10px;
    width: 365px;
    height: 279px;
    overflow: auto;
}

.reportsTable table th,
.reportsTable table td {
    white-space: nowrap;
}

td.normalWrap {
    white-space: normal !important;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 8px 16px 0 !important;
}

.caseNoteCard .ant-card-extra {
    padding: 0;
}

.ant-carousel .queueingCarousel .slick-slide {
    height: calc(100vh - 226px);
}
