/* body {
    background-color: #f0f2f5 !important;
}

.button {
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
}*/

.sideBarCollapseTrigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.sideBarCollapseTrigger:hover {
    color: #1890ff;
}

.cursorPointer {
    cursor: pointer;
}

/* react quill */
.editorContent:hover {
    cursor: pointer;
}

.editorContentHightLight {
    color: #0084ff;
}
.ql-editor {
    min-height: 80px;
}
.ql-color .ql-picker-options [data-value="remove-color"] {
    background: none !important;
    width: 100% !important;
    height: 20px !important;
    text-align: center;
}
.ql-color .ql-picker-options [data-value="remove-color"]:before {
    content: "Remove Color";
}
.ql-color .ql-picker-options [data-value="remove-color"]:hover {
    border-color: transparent !important;
}

/* CHAT */
.cwaChat .leftM {
    float: left;
    background-color: #e2e2e2;
    color: #505050;
    padding: 10px;
    border-radius: 4px;
    max-width: 210px;
    clear: both;
}

.cwaChat .rightM {
    float: right;
    background-color: #1890ff;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    max-width: 210px;
    clear: both;
}

.cwaChat .ant-popover-arrow {
    display: none;
}

.cwaChat {
    top: 58px !important;
}

/* Image Overlay */
.image-overlay-container {
    width: 104px;
    height: 104px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: inline-block;
    margin: 16px 8px 0px 0px;
}
.image-overlay-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    /* border: 1px solid #ccc; */
}
.image-overlay-content .image-overlay-content-overlay {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}
.image-overlay-content:hover .image-overlay-content-overlay {
    opacity: 1;
}
.image-overlay-content-image {
    width: 100%;
    height: 100%;
}
.image-overlay-content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
.image-overlay-content:hover .image-overlay-content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}
.image-overlay-content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}
.image-overlay-content-details p {
    color: #fff;
    font-size: 0.8em;
}
.image-overlay-fadeIn-top {
    top: 20%;
}

.youtubeContainer {
    width: 100%;
    height: calc(100vh - 226px);
}

.printPaperContainer {
    line-height: 1.5715;
    font-size: 14px;
    font-family: "Times New Roman", Times, serif;
}
.printPaperContainer h1,
.printPaperContainer h2,
.printPaperContainer h3,
.printPaperContainer h4,
.printPaperContainer h5,
.printPaperContainer h6,
.printPaperContainer p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
    }
}

.ant-menu-inline-collapsed {
    width: 78px !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

#patientPersonalForm .ant-form-item {
    display: block !important;
}

/* chat */
.cwaChat .ant-popover-inner-content {
    padding: 0;
    border-radius: 8px;
}

.cwaChat .ant-popover-arrow {
    border-top-color: #1890ff !important;
    border-left-color: #1890ff !important;
}

/* .cwaChat .ant-drawer-body {
    padding: 0;
} */

.cwaSketchBackgroundContentList .ant-popover-inner-content {
    padding: 5px 0 5px 10px;
    width: 365px;
    height: 279px;
    overflow: auto;
}

.reportsTable table th,
.reportsTable table td {
    white-space: nowrap;
}

td.normalWrap {
    white-space: normal !important;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 8px 16px 0 !important;
}

.caseNoteCard .ant-card-extra {
    padding: 0;
}

.ant-carousel .queueingCarousel .slick-slide {
    height: calc(100vh - 226px);
}

